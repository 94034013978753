/*
 -	Variables - Section
 */


$section-p-lg-xs:                 $spacer * 12;
$section-p-lg-xl:                 $spacer * 13;

$section-p-sm-xs:                 $spacer * 1.5;
$section-p-sm-xl:                 $spacer * 2;

$section-image-opacity-25:        .25;
$section-image-opacity-50:        .5;
$section-image-opacity-75:        .75;
