/*
 -	Variables - General
 */


$body-bg:                         $theme-bg;
$border-radius:                   5px;
$border-radius-big:               10px;
$general-full-width:              1920px;
$general-box-shadow-black:        0 0 ( $spacer / 2 ) #00000029;
