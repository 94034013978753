/*
 -	Variables - Expanding List
 */


$expanding-list-border-color:      #707070;

$expanding-list-btn-green-xs:      32px;
$expanding-list-btn-green-xl:      32px;
$expanding-list-btn-square-xs:     24px;
$expanding-list-btn-square-xl:     18px;

$expanding-list-section-spacing:   ( $spacer * .1 );
$expanding-list-category-spacing:  ( $spacer / 6 );
$expanding-list-leaf-spacing:      ( $spacer * .1 );

$expanding-list-dot-size:          8px;
