/*
 -	Variables - Colors
 */


$theme-white:                   #fff;                              // Pure white color
$theme-black:                   #000;                              // Pure black color
$theme-black-second:            #36322a;
$theme-brown:                   #874400;
$theme-gray-dark:               #36332a;
$theme-gray:                    #5c5c5c;
$theme-gray-medium:             #b1b1b1;
$theme-gray-medium-1:           #a8a8a8;
$theme-gray-medium-2:           rgba(#e5e5e5, .3);
$theme-gray-light:              #eeeeee;
$theme-green:                   #006c4e;
$theme-orange:                  #ee8a25;
$theme-red:                     #da0800;
$theme-yellow:                  #fee49f;




$theme-bg:                      $theme-white;
$primary:                       $theme-green;
$secondary:                     $theme-black;
$danger:                        $theme-red;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary':    $theme-green,
    'secondary':  $theme-orange,
  ),
  $theme-colors
);
