/*
 -	Variables - Tables
 */


$table-cell-padding-x:        ( $spacer * .8 );
$table-cell-padding-y:        ( $spacer / 1.5 );
$table-border-width:          0;
$table-accent-bg:             transparent;
$table-striped-order:         even;
$table-th-font-weight:        300;
