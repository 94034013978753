/*
 -  Variables - Liferay theme
 -
 -  This variables needs to be set for Liferay to work properly with custom styles
 -  {$lf-class} - standard class in liferay theme
 -  {$theme-class} - custom class to add specificity and overwrite liferay theme
 */


$lf-class:                        '.aopk-lfr';
$theme-class:                     '.aopk-theme';
