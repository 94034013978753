/*
 -	Variables - Calendar
 */


$calendar-item-height:            145px;
$calendar-item-max-w-xs:          340px;
$calendar-item-max-w-xl:          380px;
$calendar-content-max-w-xs:       165px;
$calendar-content-max-w-xl:       195px;
$calendar-box-h:                  100px;
$calendar-box-w:                  80px;