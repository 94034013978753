/*
 -  Theme typography
 -
 -  This file contains @font-face declarations.
 */

/*
 -	Pridi
 */

// Font weight: Extra light
@include font-face('.ttf', 'Pridi', '../assets/fonts/pridi/pridi-extralight', 100);

// Font weight: Light
@include font-face('.ttf','Pridi', '../assets/fonts/pridi/pridi-light', 300);

// Font weight: Regular
@include font-face('.ttf','Pridi', '../assets/fonts/pridi/pridi-regular', 400);

// Font weight: Medium
@include font-face('.ttf','Pridi', '../assets/fonts/pridi/pridi-medium', 500);

// Font weight: Semi bold
@include font-face('.ttf','Pridi', '../assets/fonts/pridi/pridi-semibold', 600);

// Font weight: Bold
@include font-face('.ttf','Pridi', '../assets/fonts/pridi/pridi-bold', 700);



/*
 -	ITCFranklinGothic
 */


// Font weight: Book Regular
@include font-face('.otf', 'Franklin Gothic Book', '../assets/fonts/franklin-gothic/franklin-gothic-book', 400);

// Font weight: Book Italic
@include font-face('.otf', 'Franklin Gothic Book', '../assets/fonts/franklin-gothic/franklin-gothic-book-italic', 400, italic);

// Font weight: Heavy
@include font-face('.otf', 'Franklin Gothic Heavy', '../assets/fonts/franklin-gothic/franklin-gothic-heavy', 700);

// Font weight: Medium
@include font-face('.ttf', 'Franklin Gothic Medium Cond', '../assets/fonts/franklin-gothic/franklin-gothic-medium-cond', 500);

// Font weight: Demi
@include font-face('.otf', 'Franklin Gothic Demi', '../assets/fonts/franklin-gothic/franklin-gothic-demi', 600);

// Font weight: Demi Italic
@include font-face('.otf', 'Franklin Gothic Demi', '../assets/fonts/franklin-gothic/franklin-gothic-demi', 600, italic);

// Font weight: Demi Cond
@include font-face('.otf', 'Franklin Gothic Demi Cond', '../assets/fonts/franklin-gothic/franklin-gothic-demi-cond', 600);