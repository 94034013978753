/*
 -	Variables - Z-indexes
 */


$header-top-bar-menu-z-index:                     10;
$mobile-menu-z-index:                             10;

$slick-slider-z-index:                            2;
$slick-slider-controls-z-index:                   5;

$banner-image-z-index:                            1;
$banner-text-z-index:                             3;
$banner-gradient-z-index:                         3;

$select-arrow-z-index:                            1;
$checkbox-icon-z-index:                           1;
$file-input-z-index:                              5;

$signpost-desktop-z-index:                        6;
$signpost-tiles-decoration:                       4;
$signpost-tiles-card:                             3;
$signpost-tiles-overlay:                          2;

$section-image-z-index:                           1;
$section-container-z-index:                       2;

$yearbook-image-wrapper-z-index:                  1;
$yearbook-btn-wrapper-z-index:                    2;

$slider-photos-z-index-bottom-layer:              1;
$slider-photos-z-index-top-layer:                 2;

$tips-z-index:                                    2;

$expanding-list-z-index:                          2;

$horizontal-navigation-wrapper-z-index:           1;
$horizontal-navigation-z-index:                   2;

$scroll-to-top-color-z-index:                     8;

$category-filter-dropdown-menu-z-index:           7;