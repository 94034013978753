/*
 -	Variables - Forms
 */


// Inputs-bootstrap vars
$input-height:                                        48px;
$input-border-width:                                  0;
$input-border-color:                                  transparent;
$input-bg:                                            #eeeeee;
$input-disabled-bg:                                   $input-bg;
$input-color:                                         $theme-gray;
$input-color-placeholder:                             lighten($input-color, 25%);
$input-font-size:                                     $font-size-base;

$label-holder-height:                                 $spacer;

$input-base-vertical:                                 ( $spacer / 3 );
$input-base-horizontal:                               ( $spacer / 1.5 );
$input-border-radius:                                 $border-radius;

$input-active-color:                                  $theme-green;
$input-active-color-state:                            darken($theme-green, 10%);

$input-error-color:                                   $danger;
$input-error-active-color-state:                      darken($danger, 15%);

$input-transition:                                    all .3s ease;

$checkbox-radio-size:                                 22px;
$radio-inner-size:                                    10px;
$checkbox-radio-border-size:                          2px;
