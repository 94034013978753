/*
 -  Print
 -
 -  Default print styles from typography and general layout
 */


/* stylelint-disable selector-max-universal, declaration-no-important */
* {
	text-shadow: none !important;
	// Black prints faster: h5bp.com/s
	color: $theme-black !important;
	background: transparent !important;
	box-shadow: none !important;
	height: auto !important;
	width: auto !important;
	font-size: 1.5rem;
}

a,
a:visited {
	text-decoration: underline;
	color: $theme-black !important;
}

a[href]:after {
	content: ' (' attr(href) ')';
}

abbr[title]:after {
	content: ' (' attr(title) ')';
}

// Don't show links for images, or javascript/internal links
a[href^='javascript:']:after,
a[href^='#']:after {
	content: '';
}

pre,
blockquote {
	border: 1px solid lighten($theme-black, 20%);
	page-break-inside: avoid;
}

thead {
	display: table-header-group; // h5bp.com/t
}

tr,
img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
	display: none !important;
}

picture {
	display: none;
}

p,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h2,
h3 {
	page-break-after: avoid;
}

h1 {
	font-size: 2.2rem;
}

h2 {
	font-size: 2.1rem;
	font-weight: 700 !important;

	span {
		font-size: 2.1rem;
		font-weight: 700 !important;
	}
}

h3 {
	font-size: 1.9rem;

	span {
		font-size: 1.9rem;
		font-weight: 700 !important;
	}
}

h4 {
	font-size: 1.8rem;
}

// Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
// Once fixed, we can just straight up remove this.
select {
	background: $theme-white !important;
}

// Bootstrap components
.navbar {
	display: none;
}

.table {
	td,
	th {
		background-color: $theme-white !important;
	}
}

.btn,
.dropup > .btn {
	> .caret {
		border-top-color: $theme-black !important;
	}
}

.label {
	border: 1px solid $theme-black;
}

.table {
	border-collapse: collapse !important;
}

.table-bordered {
	th,
	td {
		border: 1px solid darken($theme-white, 10%) !important;
	}
}

.aopk-theme img,
iframe {
	display: none !important;
}

.aopk-theme .header {
	height: 200px;
}

.aopk-lfr .aopk-theme .header__inner {
	display: none !important;
}

.aopk-theme .banner--full-height::before {
	content: none !important;
}

.aopk-lfr .banner-wrapper {
	display: none !important;
}

.aopk-theme .signpost__item--highlighted {
	background-color: transparent !important;
	color: $theme-black !important;
}

.aopk-theme .signpost__item--highlighted::before,
.aopk-theme .signpost__item--highlighted::after {
	border: none !important;
}

.aopk-theme .signpost__heading {
	color: $theme-black !important;
}

.aopk-theme .signpost__list-link {
	color: $theme-black !important;
}

.aopk-theme .link-arrow--dark-bg {
	color: $theme-black !important;
}

.aopk-theme .mapa_svg {
	display: none !important;
}

footer {
	display: none !important;
}

.aopk-theme .btn.btn-secondary--green {
	background-color: $theme-white !important;
	color: $theme-black !important;
}

.aopk-theme .btn.btn-primary {
	background-color: $theme-white !important;
	color: $theme-black !important;
}

.aopk-theme .btn.btn-primary .btn__icon {
	background-color: $theme-white !important;
}

.aopk-theme .btn.btn--simple-icon::before {
	background-color: $theme-white !important;
}

.aopk-theme .trailer-card {
	background-color: $theme-white !important;
}

/* stylelint-enable selector-max-universal, declaration-no-important */