/*
 -	Variables - Signpost
 */


$signpost-border:               rgba(#b1b1b1, .5);
$signpost-border-frame-width:   10px;
$signpost-arrow-color:          $theme-gray-medium;
$signpost-item-gap:             34px;
$signpost-link-color:           #2e2e2e;
$signpost-background:           #fdfdfd;
$signpost-content-width:        280px;
