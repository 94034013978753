/*
 -	Variables - Header
 */


$header-height-xs:                                110px;
$header-height-xl:                                289px;
$header-top-bar-height:                           50px;
$header-logo-height:                              90px;
$header-top-bar-menu-min-width:                   450px;
$header-search-bar-width-xl:                      415px;
$header-search-bar-height-xl:                     42px;