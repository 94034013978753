/*
 -	Variables - Banner
 */


$banner-image-height-mobile:   350px;
$banner-image-height:          500px;

$banner-heading-fs-mobile:     34px;
$banner-heading-lh-mobile:     50px;

$banner-heading-fs:            72px;
$banner-heading-lh:            80px;

$banner-arrow-height-mobile:   20px;
$banner-arrow-height:          40px;

$banner-arrow-slider:          40px;

$banner-logo-max-width:        140px;

$banner-full-width:            $general-full-width;
$banner-full-height:           1080px;
$banner-full-height-min-h:     950px;

$banner-gradient-mobile:       transparent linear-gradient(180deg, #2f5b8b 10%, #2f5b8b00 100%) 0% 0% no-repeat padding-box;
$banner-gradient:              transparent linear-gradient(180deg, #2f5b8b 20%, #2f5b8b00 100%) 0% 0% no-repeat padding-box;
$banner-gradient-height:       300px;
$banner-gradient-height-mobile:170px;
