/*
 -	Variables - Navigation
 */


$navigation-mobile-toggle-width:                  30px;
$navigation-mobile-toggle-item-height:            5px;
$navigation-mobile-list-item-padding:             0 0 ( $spacer / 3 );
$navigation-mobile-border-bottom-xs:              1px solid rgba($theme-white, .2);
$navigation-language-switcher-size:               22px;
$navigation-menu-primary-item-height-xl:          44px;
$navigation-menu-primary-item-padding-x-xl:       $spacer;
$navigation-submenu-height-xl:                    50px;
$navigation-submenu-toggler-size:                 42px;