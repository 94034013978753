/*
 -	Variables - Breadcrumb
 */


$breadcrumb-font-size:              $small-font-size;

$breadcrumb-padding-y:              ( $spacer / 3.75 );
$breadcrumb-padding-x:              ( $spacer / 2 );

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     rgba($theme-gray-dark, .2);
$breadcrumb-divider-color:          $theme-orange;
$breadcrumb-active-color:           $theme-white;

$breadcrumb-border-radius:          0;