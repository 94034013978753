/*
 -	Variables - Icons
 */


$icons: (
	'arrow-left',
	'arrow-right',
	'arrow-down',
	'arrow-up',
	'arrow-thin-left',
	'arrow-thin-right',
	'arrow-thin-down',
	'arrow-thin-up',
	'sort-alt',
	'sort-amount-down',
	'sort-amount-up-alt',
	'calendar',
	'caret-left',
	'caret-right',
	'caret-down',
	'caret-up',
	'check',
	'point',
	'search',
	'tree-leaf',
	'phone',
	'mail',
	'home',
	'domy-prirody',
	'chci-podat-stiznost',
	'chci-podat-zadost',
	'chci-pozadat-o-informace-nebo-data',
	'chci-se-stat-biologem',
	'chci-ziskat-dotaci',
	'jak-dokumentujeme-prirodu',
	'jak-chranime-prirodu-a-krajinu',
	'jak-pecujeme-o-prirodu-a-krajinu',
	'potrebuji-povoleni-nebo-stanovisko',
	'tipy-na-vylet',
	'rozmanitosti-prirody',
	'facebook',
	'instagram',
	'linkedin',
	'youtube',
	'chuze',
	'kolo',
	'plus',
	'minus',
	'gallery',
	'cancel'
);
